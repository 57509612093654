// @flow
import * as React from 'react';
import { graphql } from 'gatsby';
import compose from 'recompose/compose';
import withStyles, {
  type WithStyles,
} from '@material-ui/core/styles/withStyles';
import type { Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import map from 'lodash/map';

import withNodePage, {
  type WithNodePageProps,
} from '~plugins/prismic/hocs/withNodePage';
import StoryCard from '~components/StoryCard';
import useGroupedStoriesByTag from '~hooks/useGroupedStoriesByTag';
import getStoryTagType from '~helpers/getStoryTagType';
import type { PrismicStoriesPage } from '~schema';

export type ClassKey = 'root' | 'bodySection';

export type Props = {
  // $FlowFixMe --> Reason: all branches are incompatible: Either cannot instantiate `WithStyles` because  string literal `root` [1] is incompatible with  `StyleRules` [2] in type argument `Rules`.
  ...$Exact<WithStyles<ClassKey>>,
  ...$Exact<WithNodePageProps<PrismicStoriesPage>>,
};

const styles = (theme: Theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    marginTop: 51,
    [theme.breakpoints.up('lg')]: {
      marginTop: 89,
    },
  },
  bodySection: {},
});

const StoriesPage = ({ classes, data }: Props) => {
  const page = data?.prismicStoriesPage;
  const stories = data?.allPrismicStory?.nodes;
  const storyTagData = getStoryTagType(page?.data?.stories_tag);
  const pageTag = storyTagData?.data?.name?.text;
  const storiesByTag = useGroupedStoriesByTag(stories);

  return (
    <div className={classes.root}>
      <Grid container spacing={1} className={classes.bodySection}>
        {pageTag &&
          map(storiesByTag[pageTag], (story, index: number) => (
            <Grid
              key={story?.data?.title?.text || index}
              item
              xs={12}
              sm={6}
              md={4}
            >
              <StoryCard
                node={story}
                title={story?.data?.title}
                subtitle={story?.data?.subtitle?.text}
                linkLabel={story?.data?.title?.text}
                tag={story?.data?.story_tag}
                backgroundImage={story?.data?.background_image?.url}
              />
            </Grid>
          ))}
      </Grid>
    </div>
  );
};

export default compose(
  withNodePage<PrismicStoriesPage, *>({
    getNode: data => data?.prismicStoriesPage,
  }),
  withStyles<ClassKey, *, Props>(styles),
)(StoriesPage);

export const query = graphql`
  query StoriesPageQuery($prismicId: ID, $prismicLocaleId: String) {
    prismicStoriesPage(prismicId: { eq: $prismicId }) {
      id
      lang
      first_publication_date
      last_publication_date
      tags
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        meta_keywords {
          meta_keyword {
            text
          }
        }
        meta_image {
          alt
          url
          dimensions {
            width
            height
          }
        }
        name {
          text
        }
        stories_tag {
          document {
            ... on PrismicStoryTag {
              id
              data {
                name {
                  text
                  html
                }
              }
            }
          }
        }
      }
    }
    allPrismicStory(filter: { lang: { eq: $prismicLocaleId } }) {
      nodes {
        id
        uid
        type
        lang
        first_publication_date
        tags
        data {
          title {
            text
            html
          }
          subtitle {
            text
            html
          }
          background_image {
            alt
            url
            fluid(maxWidth: 1800) {
              ...GatsbyPrismicImageFluid
            }
          }
          story_tag {
            id
            document {
              ... on PrismicStoryTag {
                id
                data {
                  name {
                    text
                    html
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
