// @flow
import * as React from 'react';
import fp$compose from 'lodash/fp/compose';
import groupBy from 'lodash/groupBy';
import toPairs from 'lodash/fp/toPairs';
import sortBy from 'lodash/fp/sortBy';
import filter from 'lodash/fp/filter';
import concat from 'lodash/concat';
import reverse from 'lodash/fp/reverse';

export default function useGroupedStoriesByTag(stories: ?Array<?any>) {
  const sortStoriesWithRelevance = fp$compose(
    sortBy(story => story?.data?.story_relevance),
    filter(story => story?.data?.story_relevance),
  );
  const sortStoriesWithoutRelevance = fp$compose(
    reverse,
    sortBy(story => story?.data?.date || story?.first_publication_date),
    filter(story => !story?.data?.story_relevance),
  );

  const groupedStories = React.useMemo(() => {
    if (stories) {
      const storiesWithRelevance = stories
        ? sortStoriesWithRelevance(stories)
        : undefined;
      const storiesWithoutRelevance = stories
        ? sortStoriesWithoutRelevance(stories)
        : undefined;

      const sortedStories = concat(
        storiesWithRelevance,
        storiesWithoutRelevance,
      );

      const sortedStoriesWithoutHiddenTag = sortedStories.filter(
        story => story && !story.tags.includes('hidden'),
      );

      return groupBy(
        sortedStoriesWithoutHiddenTag,
        story => story?.data?.story_tag?.document?.data?.name?.text,
      );
    }
    return undefined;
  }, [stories]);

  const toPairsGroupedStories = toPairs(groupedStories);

  return toPairsGroupedStories.reduce(
    (acc, storiesGroup) => ({
      ...acc,
      [storiesGroup[0]]: storiesGroup[1],
    }),
    {},
  );
}
